import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <header class="header navbar-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="nav-inner">
                                <nav class="navbar navbar-expand-lg">
                                    <a class="navbar-brand" href="/">
                                        <img src="assets/images/logo/white-logo.png" alt="Logo" />
                                    </a>
                                    <button class="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="toggler-icon"></span>
                                        <span class="toggler-icon"></span>
                                        <span class="toggler-icon"></span>
                                    </button>
                                    <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" class="navbar-nav ms-auto">
                                            <li class="nav-item">
                                                <a href="\" class="active" aria-label="Toggle navigation">Ana Sayfa</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="/help" aria-label="Toggle navigation">Yardım İçerikleri</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="/contact" aria-label="Toggle navigation">İletişim</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="button home-btn">
                                        <a href="https://play.google.com/store/apps/details?id=com.softsis.ustacuzdan" target='_blank' class="btn">Şimdi Marketten İndirin</a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
            <footer class="footer section">
                <div class="footer-top">
                    <div class="container">
                        <div class="inner-content">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="single-footer f-about">
                                        <div class="logo">
                                            <a href="/">
                                                <img src="assets/images/logo/white-logo.png" alt="#" />
                                            </a>
                                        </div>
                                        <p>Boya Ustalarının Yeni Sadakat Uygulaması</p>
                                        <h4 class="social-title">Bizi Takip Edin:</h4>
                                        <ul class="social">
                                            <li><a href="#"><i class="lni lni-facebook-filled"></i></a></li>
                                            <li><a href="#"><i class="lni lni-instagram"></i></a></li>
                                            <li><a href="#"><i class="lni lni-twitter-original"></i></a></li>
                                            <li><a href="#"><i class="lni lni-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="copyright-area">
                    <div class="container">
                        <div class="inner-content">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <p class="copyright-text">© 2024 Sesa Boya Teknolojileri ve Kimya San. A.Ş.</p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <p class="copyright-owner">Altyapı ve projelendirme <a href="https://www.softsis.com.tr/" target="_blank">Softsis Yazılım Teknolojileri A.Ş.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <a href="#" class="scroll-top">
                <i class="lni lni-chevron-up"></i>
            </a>
        </>
    )
};

export default Layout;