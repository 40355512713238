import { Accordion, Spinner, Row, Col, Card, Button } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { ListCategoryContent } from '../services/HelpService';

const Help = () => {

    const [showModal, setShowModal] = useState(false);
    const [datas, setDatas] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setShowModal(true)

        ListCategoryContent({})
            .then((res) => {
                setDatas(res.data.data);
            }).catch((e) => {

            }).finally(() => {
                setShowModal(false)
            })
    }

    return (
        <>
            <div style={{ height: 110 }}>

            </div>
            <section class="freatures section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-12">
                            {showModal && <Row className='justify-content-md-center mb-4 mt-4'>
                                <Col md="auto">
                                    <Spinner animation="border" variant="primary" size='lg' />
                                </Col>
                            </Row>}

                            {datas.length > 0 && datas.map((e, index) =>
                                <Row className='justify-content-md-center mt-4'>
                                    <h1 style={{ fontSize: 24, fontWeight: '700', textAlign: 'center' }}>{e.name}</h1>
                                    {e.helpCategories.map((g, index) =>
                                        <div>
                                            <h2 style={{ fontSize: 17, fontWeight: '600', marginTop: 20, marginBottom: 20 }}>{g.name}</h2>
                                            <Accordion>
                                                {g.helps.map((h, indexx) =>
                                                    <Accordion.Item eventKey={h.idHelpContent}>
                                                        <Accordion.Header><span className='fs-5'>{h.title}</span></Accordion.Header>
                                                        <Accordion.Body className='fs-6 text-break'>
                                                            {h.content}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )}
                                            </Accordion>
                                        </div>
                                    )}
                                </Row>
                            )}
                            <Row className='justify-content-md-center mb-4 mt-5'>
                                <Card className='text-center w-50'>
                                    <Card.Header as="h5">DESTEK</Card.Header>
                                    <Card.Body>
                                        <Card.Title>Daha fazla yardıma mı ihtiyacınız var?</Card.Title>
                                        <Card.Text>
                                            Destek ekibimize mesaj yazın.
                                        </Card.Text>
                                        <Button variant="outline-dark" size='lg' href="/contact">İletişim</Button>

                                    </Card.Body>
                                </Card>
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Help;