import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Help from "./pages/Help";
import Home from "./pages/Home";
import DeleteAccount from "./pages/DeleteAccount";
import Contact from './pages/Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="deleteaccount" element={<DeleteAccount />} />
        <Route path="contact" element={<Contact />} />
        <Route path="help" element={<Help />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
reportWebVitals();
