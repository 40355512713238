const Home = () => {
    return (
        <>
            <section class="hero-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-12 col-12">
                            <div class="hero-content">
                                <h4>Füller Usta Cüzdan</h4>
                                <h1>Boya Ustalarının<br />
                                    Yeni Uygulaması</h1>
                                <p>Kazandığın puanları, hediye mağazasında dilediğince harca!</p>
                                <div class="button">
                                    <a href="#" class="btn" >
                                        <img src="./assets/images/icons/apple.png" width={32} height={32} />
                                        <span style={{ paddingLeft: 10, fontSize: 18 }}>IOS için İndir</span>
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.softsis.ustacuzdan" target="_blank" class="btn" style={{marginLeft:10}} >
                                        <img src="./assets/images/icons/android.png" width={32} height={32} />
                                        <span style={{ paddingLeft: 10, fontSize: 18 }}>Android için İndir</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-12">
                            <div class="hero-image wow fadeInRight" data-wow-delay=".4s">
                                <img class="main-image" src="./assets/images/banners/banner1.png" alt="#" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="freatures section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-12">
                            <div class="image wow fadeInLeft" data-wow-delay=".3s">
                                <img src="./assets/images/banners/banner2.png" alt="#" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="content">
                                <h3 class="heading wow fadeInUp" data-wow-delay=".5s"><span>Füller Usta Cüzdan</span> Herkesin Kullanımına Açık</h3>
                                <div class="single-feature wow fadeInUp" data-wow-delay=".6s">
                                    <div class="f-icon">
                                        <i class="lni lni-vector"></i>
                                    </div>
                                    <h4>Kolay Kullanım</h4>
                                    <p>Füller Usta Cüzdan, kullanıcı dostu arayüzü ile kolayca kullanılabilir. Uygulamayı indirip kaydolduktan sonra, boya kovaları üzerindeki QR kodları okutarak hemen puan kazanmaya başlayabilirsiniz.</p>
                                </div>
                                <div class="single-feature wow fadeInUp" data-wow-delay=".7s">
                                    <div class="f-icon">
                                        <i class="lni lni-pencil-alt"></i>
                                    </div>
                                    <h4>Puan Kazanma</h4>
                                    <p>Füller marka boya ürünlerinden satın aldığınızda, ürün üzerindeki QR kodu uygulama aracılığıyla okutarak puan kazanabilirsiniz. Her ürün, farklı puan değerlerine sahiptir ve daha fazla puan kazanmak için daha fazla ürün satın alabilirsiniz.</p>
                                </div>
                                <div class="single-feature wow fadeInUp" data-wow-delay="0.8s">
                                    <div class="f-icon">
                                        <i class="lni lni-dashboard"></i>
                                    </div>
                                    <h4>Güvenli ve Hızlı</h4>
                                    <p>Tüm işlemler, güvenli ve hızlı bir şekilde gerçekleştirilir. Kişisel bilgilerinizin güvenliği ön planda tutulur ve puanlarınızın doğru bir şekilde hesaplanması sağlanır.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="call-action">
                <div class="container">
                    <div class="inner-content">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-7 col-12">
                                <div class="text">
                                    <h2>Yeni Üyeler Özel
                                        <br /> Hediyenizi Almak için...
                                    </h2>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-5 col-12">
                                <div class="button">
                                    <a href="#" class="btn"><i class="lni lni-apple"></i> App Store
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.softsis.ustacuzdan" class="btn btn-alt"><i class="lni lni-play-store"></i> Google
                                        Play</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="faq section">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="section-title">
                                <h3 class="wow zoomIn" data-wow-delay=".2s">SSS</h3>
                                <h2 class="wow fadeInUp" data-wow-delay=".4s">Sıkça Sorulan Sorular</h2>
                                <p class="wow fadeInUp" data-wow-delay=".6s">Uygulamaya ait kullanıcılar tarafından merak edilen soruları sizler için derledik.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-12">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                            <span class="title">Uygulama ücretsiz mi?</span><i
                                                class="lni lni-plus"></i>
                                        </button>
                                    </h2>
                                    <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Evet, "Füller Usta Cüzdan" tamamen ücretsiz bir uygulamadır. Hiçbir gizli ücret veya abonelik ücreti bulunmamaktadır.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-12 xs-margin">
                            <div class="accordion" id="accordionExample2">

                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                            <span class="title">Hediyeleri nasıl talep edebilirim?</span><i
                                                class="lni lni-plus"></i>
                                        </button>
                                    </h2>
                                    <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2"
                                        data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Kazandığınız puanlarla, uygulama içerisindeki hediye mağazasından dilediğiniz ödülü seçip talep edebilirsiniz. Hediye talepleriniz, belirtilen süre içerisinde adresinize gönderilecektir.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Home;