import { Breadcrumb, Form, Row, Col, Card, Button, Image, Container } from 'react-bootstrap';
import { useState } from "react";
import InputMask from 'react-input-mask';

import * as formik from 'formik';
import * as yup from 'yup';

const DeleteAccount = () => {
    const { Formik } = formik;
    const [loading, setLoading] = useState(false);
    const [mobileCode, setMobileCode] = useState(false);
    const [messageSend, setMessageSend] = useState(false);
    const schema = yup.object().shape({
        message: yup.string().min(10, "Lütfen en az '10' karakter giriş yapınız.").max(500, "Lütfen en fazla '500' karakter giriş yapınız.").required("Lütfen mesajınızı yazınız."),
    });
    const schemaCode = yup.object().shape({
        code: yup.string().min(6, "Lütfen en az '6' karakter giriş yapınız.").max(6, "Lütfen en fazla '6' karakter giriş yapınız.").required("Lütfen doğrulama kodunu giriniz."),
    });
    return (
        <>
            <div style={{ height: 110 }}>

            </div>
            <section class="freatures section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-12">

                            <Card style={{ minHeight: '400px' }}>

                                {!messageSend ?
                                    <Card.Body>
                                        <Card.Title className='mb-3 mt-2 fs-4'>Hesap Silme Talebi</Card.Title>
                                        <Formik
                                            validationSchema={schema}
                                            onSubmit={(e) => {
                                                setLoading(true);
                                                setMessageSend(true);
                                                setMobileCode(true);
                                            }}
                                            initialValues={{
                                                email: '',
                                                phone: '',
                                                shortDesc: '',
                                                message: ''
                                            }}
                                        >
                                            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Row className="mb-3">

                                                        <Form.Group as={Col} controlId="phone">
                                                            <Form.Label>Telefon Numaranız:</Form.Label>
                                                            <InputMask
                                                                mask="(999) 999 99 99"
                                                                className="form-control"
                                                                placeholder=""
                                                                value={values.phone}
                                                                isValid={touched.phone && !errors.phone}
                                                                isInvalid={!!errors.phone}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        'phone',
                                                                        e.target.value.replace(/\D/g, '')
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Text id="phone" style={{ fontSize: 12 }}>
                                                                Uygulamada kullandığınız telefon numarasını yazınız.
                                                            </Form.Text>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.phone}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Row>

                                                    <Form.Group className="mb-3" controlId="message">
                                                        <Form.Label>Hesabınızı Neden Silmek İstiyorsunuz? : *</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            value={values.message}
                                                            onChange={handleChange}
                                                            isValid={touched.message && !errors.message}
                                                            isInvalid={!!errors.message}
                                                            maxLength={500}
                                                        />
                                                        <Form.Text id="message" style={{ fontSize: 12 }}>
                                                            Sebebi olabildiğince detaylı olarak yazınız.
                                                        </Form.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Button variant="primary" type="submit">
                                                        Gönder
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Card.Body> :
                                    mobileCode ?
                                        <Card.Body>
                                            <Card.Title className='mb-3 mt-2 fs-4'>Doğrulama Kodu</Card.Title>
                                            <Formik
                                                validationSchema={schemaCode}
                                                onSubmit={(e) => {
                                                    setLoading(true);
                                                    setMessageSend(true);
                                                    setMobileCode(false);
                                                }}
                                                initialValues={{
                                                    code: ''
                                                }}
                                            >
                                                {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                                    <Form noValidate onSubmit={handleSubmit}>
                                                        <Row className="mb-3">

                                                            <Form.Group as={Col} controlId="code">
                                                                <Form.Label>Doğrulama Kodu:</Form.Label>
                                                                <Form.Control
                                                                    placeholder=""
                                                                    value={values.code}
                                                                    isValid={touched.code && !errors.code}
                                                                    isInvalid={!!errors.code}
                                                                    onChange={handleChange}
                                                                    maxLength={6}
                                                                />
                                                                <Form.Text id="code" style={{ fontSize: 12 }}>
                                                                    Lütfen telefonunuza gönderilen kodu giriniz.
                                                                </Form.Text>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.code}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>

                                                        <Button variant="primary" type="submit">
                                                            Gönder
                                                        </Button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Card.Body>
                                        :
                                        <Card.Body className='text-center pt-5 pb-5'>
                                            <Image src="/ok-icon.png" roundedCircle width={120} />
                                            <Card.Title className='mt-5 fs-3'>Talebiniz Alındı</Card.Title>
                                            <Card.Text className='mt-4 fs-5'>Hesabınız en kısa sürede kalıcı olarak silinecektir ve tarafınıza geri dönüş sağlanacaktır.</Card.Text>
                                        </Card.Body>
                                }
                            </Card>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default DeleteAccount;